var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.groups)?_c('v-card',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"px-2 pt-7"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.groups,"item-text":"groupName","item-value":"guid","return-object":"","label":_vm.$t('prescriptions.prescriptionsGroupName')},model:{value:(_vm.selectedGroup),callback:function ($$v) {_vm.selectedGroup=$$v},expression:"selectedGroup"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-actions',{staticClass:"elevation-1",style:(_vm.$vuetify.theme.isDark
                    ? 'background-color:#363636'
                    : 'background-color:whitesmoke')},[_c('v-row',{staticClass:"ma-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"button mt-2",staticStyle:{"font-size":"20px"}},[_vm._v(" "+_vm._s(_vm.$t("prescriptions.prescriptionsItems"))+" ")]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"float-right",attrs:{"color":"primary","dark":""},on:{"click":_vm.addItem}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-plus")])],1)]}}],null,false,2322366851)},[_c('span',{},[_vm._v(" "+_vm._s(_vm.$t("prescriptions.addPrescriptionItem"))+" ")])])],1)],1)],1),_c('v-card-text',[_c('v-list',_vm._l((_vm.prescriptionModels),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-combobox',{attrs:{"label":_vm.$t('prescriptions.itemName'),"items":_vm.items,"item-text":"itemName","item-value":"itemName","return-object":"","persistent-hint":"","single-line":"","hint":item.brandName
                                  ? 'Brand Name: ' + item.brandName
                                  : ''},on:{"change":function (val) {
                                  item.brandName = val.brandName
                                    ? val.brandName
                                    : '';
                                  item.itemName = val.itemName
                                    ? val.itemName
                                    : val;
                                }},model:{value:(item.itemName),callback:function ($$v) {_vm.$set(item, "itemName", $$v)},expression:"item.itemName"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":_vm.$t('prescriptions.dose')},model:{value:(item.dose),callback:function ($$v) {_vm.$set(item, "dose", $$v)},expression:"item.dose"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":_vm.$t('prescriptions.usageItem')},model:{value:(item.usageItem),callback:function ($$v) {_vm.$set(item, "usageItem", $$v)},expression:"item.usageItem"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":_vm.$t('notes')},model:{value:(item.note),callback:function ($$v) {_vm.$set(item, "note", $$v)},expression:"item.note"}})],1)],1),_c('v-divider')],1)],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red lighten-2"},on:{"click":function($event){return _vm.removeItem(i)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-close ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("remove"))+" ")])])],1)],1)}),1)],1),_c('v-divider')],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"white--text",attrs:{"color":"green darken-1","disabled":!_vm.valid,"min-width":100},on:{"click":_vm.save}},[_c('v-icon',[_vm._v("mdi-content-save-outline")]),_vm._v(" "+_vm._s(_vm.$t("save"))+" ")],1),_c('v-btn',{staticClass:"white--text",class:_vm.isEdit ? '' : 'd-none',attrs:{"color":"blue darken-1"},on:{"click":_vm.cancelEdit}},[_c('v-icon',[_vm._v("mdi-newspaper-variant-outline")]),_vm._v(" "+_vm._s(_vm.$t("new"))+" ")],1),_c('v-spacer')],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }